import React from 'react'
import {answerCss, faqSectionCss, faqTitleCss, qAndACss, qAndAsCss, questionCss} from './Faq.style'
import Link from 'next/link'
import {sectionMainTitle} from '../../styles/section.style'

const QAs = [
  {
    question: 'I requested access to the Beta. When my account will be activated?',
    answer: (
      <span>
        The Beta is available under limited access. Once you request access, depending on the demand, it might take a
        few days to a few weeks to get your account activated.
      </span>
    ),
  },
  {
    question: 'What data does Atayoo have access to?',
    answer: (
      <span>
        Atayoo accesses the fewest information possible from your GitHub account to authenticate yourself and contact
        you. All searches, results and recently visited items are only stored in your computer and never transit by our
        servers.
        <br />
        Read more about this in our{' '}
        <span className="link">
          <Link href="/privacy" prefetch={false} aria-label="Go to Privay Policy">
            Privacy Policy
          </Link>
        </span>
        .
      </span>
    ),
  },
  {
    question: 'How can I give feedback, suggest features, or report bugs?',
    answer: (
      <span>
        Building a product you love is our main motivation so please let us know what you think at{' '}
        <b>feedback@atayoo.com</b>. Having a public roadmap is planned.
      </span>
    ),
  },
  {
    question: 'When Atayoo will come out of beta?',
    answer: (
      <span>
        It will depend on the stability of the product and the feedback of our customers. There is no ETA for now but
        the Beta will probably last at least 1 month.
      </span>
    ),
  },
  {
    question: 'On which browser will Atayoo be available?',
    answer: (
      <span>For now, Atayoo is only available on Chrome. Adding support for Safari, Firefox and Edge is planned.</span>
    ),
  },
]

export default function Faq() {
  return (
    <div className="container-lg">
      <div css={faqSectionCss}>
        <h2 css={[faqTitleCss, sectionMainTitle]}>FAQ</h2>
        <div css={qAndAsCss}>
          {QAs.map((qa) => {
            return (
              <div key={qa.question} css={qAndACss}>
                <h3 css={questionCss}>{qa.question}</h3>
                <p css={answerCss}>{qa.answer}</p>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
