import React from 'react'
import Link from 'next/link'
import {separatorCss, linksCss, linkCss, copyrightCss} from './Footer.style'
import {APP_NAME} from '../../../constants'

const links = [
  {
    url: '/terms',
    name: 'Terms',
  },
  {
    url: '/privacy',
    name: 'Privacy Policy ',
  },
  {
    url: 'https://twitter.com/getatayoo',
    name: 'Twitter',
  },
  {
    url: 'mailto:contact@Atayoo.com',
    name: 'Contact',
  },
]

export default function Footer() {
  return (
    <div className="container-lg">
      <div css={separatorCss} />
      <div css={linksCss}>
        {links.map((link) => (
          <span className="link" key={link.url} css={linkCss}>
            <Link
              href={link.url}
              prefetch={false}
              rel={link.url.startsWith('http') ? 'noopener' : ''}
              target={link.url.startsWith('http') ? '_blank' : ''}
              aria-label={`Go to ${link.name}`}
            >
              {link.name}
            </Link>
          </span>
        ))}
      </div>
      <div css={copyrightCss}>
        {APP_NAME} is not affiliated with GitHub. GitHub and its logos are trademarks of GitHub, Inc.
      </div>
    </div>
  )
}
