import {css} from '@emotion/react'
import {PHONES_AND_DOWN_QUERY, TABLETS_AND_UP_QUERY} from '../../constants/mediaQueries'

export const planCss = css({
  position: 'relative',
  background: '#ffffff',
  display: 'flex',
  flexDirection: 'column',
  padding: '20px 25px',
  borderRadius: '5px',
  boxShadow: '1px 2px 8px rgba(0, 0, 0, 0.15)',
  boxSizing: 'border-box',
  [PHONES_AND_DOWN_QUERY]: {
    flexBasis: '100%',
    '&:nth-of-type(n+2)': {
      marginTop: '20px',
    },
  },
  [TABLETS_AND_UP_QUERY]: {
    width: 'calc(45% - 50px)',
    maxWidth: '340px',
  },
})

export const planHeaderCss = css({
  display: 'flex',
  flexDirection: 'row',
  flexGrow: 0,
  alignItems: 'baseline',
  justifyContent: 'space-between',
  marginBottom: '10px',
})

export const planNameCss = css({
  fontSize: '21px',
  fontWeight: 700,
  marginBottom: '5px',
})

export const planPriceWrapperCss = css({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'baseline',
})

export const planCurrencyCss = css({
  color: '#929fae',
  alignSelf: 'start',
  fontSize: '16px',
})

export const planPriceCss = css({
  fontSize: '40px',
  lineHeight: '40px',
})

export const planIntervalCss = css({
  fontSize: '16px',
  color: '#929fae',
})

export const planUpdateCss = css({
  fontSize: '16px',
})

export const planFeatureCss = css({
  fontSize: '16px',
  display: 'flex',
  alignItems: 'center',
})
