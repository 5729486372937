export const PHONES_AND_UP_QUERY = '@media (min-width: 576px)'
export const TABLETS_AND_UP_QUERY = '@media (min-width: 768px)'
export const DESKTOPS_AND_UP_QUERY = '@media (min-width: 992px)'
export const LARGE_DESKTOPS_AND_UP_QUERY = '@media (min-width: 1200px)'

export const PHONES_AND_DOWN_QUERY = '@media (max-width: 767.98px)'
export const TABLETS_AND_DOWN_QUERY = '@media (max-width: 991.98px)'
export const DESKTOPS_AND_DOWN_QUERY = '@media (max-width: 1199.98px)'

export const TABLETS_ONLY_QUERY = '@media (min-width: 768px) and (max-width: 991.98px)'
export const DESKTOPS_ONLY_QUERY = '@media (min-width: 992px) and (max-width: 1199.98px)'
