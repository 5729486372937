import React from 'react'
import octicons from '@primer/octicons'
import octiconsv1 from 'octicons'

type Props = {
  icon: string
  width: number | string
  height: number | string
  className?: string
  style: {[index: string]: number | string | null}
}

export default function Octicon(props: Props) {
  const {icon, width, height, className, style} = props
  const octicon = octicons[icon] || octiconsv1[icon]
  return (
    <span
      className={className}
      style={{
        display: 'inline-flex',
        fill: 'currentColor',
        ...style,
      }}
      dangerouslySetInnerHTML={{__html: octicon.toSVG({width, height})}}
    />
  )
}

Octicon.defaultProps = {
  style: {},
}
