export type PricingPlanId = 'free-usd-1' | 'pro-usd-1'
export type IPricingPlan = {
  id: PricingPlanId
  name: string
  price: number
  interval: 'month' | 'year'
  currency: 'usd'
  trialPeriod: number
  pricePerMonth: number
  perks: {
    domains: boolean
    privateRepos: boolean
    workspacesLimit: number
    repositoriesPerWorkspaceLimit: number
  }
  features: string[]
  discount?: {
    id: string
  }
}
export const FREE_PLAN: IPricingPlan = Object.freeze({
  id: 'free-usd-1',
  name: 'Free',
  price: 0,
  interval: 'month',
  currency: 'usd',
  trialPeriod: 0,
  pricePerMonth: 0,
  perks: {
    domains: false,
    privateRepos: false,
    workspacesLimit: 3,
    repositoriesPerWorkspaceLimit: 10,
  },
  features: ['3 workspaces', '10 repos. per workspace', 'Search from github.com', 'Public repositories'],
})

export const PRO_PLAN: IPricingPlan = Object.freeze({
  id: 'pro-usd-1',
  name: 'Pro',
  price: 30,
  interval: 'year',
  currency: 'usd',
  trialPeriod: 30,
  pricePerMonth: 2.5,
  perks: {
    domains: true,
    privateRepos: true,
    workspacesLimit: 7,
    repositoriesPerWorkspaceLimit: 20,
  },
  features: [
    '7 workspaces',
    '20 repos. per workspace',
    'Search from any websites',
    'Public repositories',
    'Private repositories',
  ],
  discount: {
    id: 'beta',
  },
})

export const PRICING_PLANS: {[planId in PricingPlanId]: IPricingPlan} = Object.freeze({
  'free-usd-1': FREE_PLAN,
  'pro-usd-1': PRO_PLAN,
})
