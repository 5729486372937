import React, {useEffect, useState} from 'react'
import Cookies from 'js-cookie'
import {COOKIE_CONSENT_COOKIE_NAME} from '../../config/app'
import Router from 'next/router'
import {GoogleAnalytics} from '../../clients/googleAnalytics'
import {bannerCss, bannerButtonsCss, bannerButtonCss} from './CookieConsentBanner.style'
import Button from '../Button'

const startGATracking = () => {
  GoogleAnalytics.init()
  GoogleAnalytics.trackPage()
  Router.events.on('routeChangeComplete', () => {
    GoogleAnalytics.trackPage()
  })
}

export default function CookieConsentBanner() {
  const cookieConsent = Cookies.get(COOKIE_CONSENT_COOKIE_NAME)
  const [showBanner, setShowBanner] = useState(!cookieConsent)
  const optOutCookie = () => {
    setShowBanner(false)
    Cookies.set(COOKIE_CONSENT_COOKIE_NAME, 'false', {expires: 365})
  }
  const acceptCookie = () => {
    setShowBanner(false)
    Cookies.set(COOKIE_CONSENT_COOKIE_NAME, 'true', {expires: 365})
    startGATracking()
  }

  useEffect(() => {
    const cookieConsent = Cookies.get(COOKIE_CONSENT_COOKIE_NAME)
    if (cookieConsent === 'true') {
      startGATracking()
    }
  })

  return (
    showBanner && (
      <div css={bannerCss}>
        <p>
          We use cookies to improve your experience on the site. Read more in our{' '}
          <a className="link" href="/privacy" aria-label="Go to Privay Policy">
            Privacy Policy
          </a>
          .
        </p>
        <div css={bannerButtonsCss}>
          <Button
            css={bannerButtonCss}
            size="small"
            status="secondary"
            onClick={optOutCookie}
            aria-label="Refuse usage of cookies"
          >
            Refuse
          </Button>
          <Button
            css={bannerButtonCss}
            size="small"
            status="primary"
            onClick={acceptCookie}
            aria-label="Accept usage of cookies"
          >
            Accept
          </Button>
        </div>
      </div>
    )
  )
}
