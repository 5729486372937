import React from 'react'

import {plansCss, pricingCss, pricingTitleCss, pricingBgCss} from './Pricing.style'
import {PRICING_PLANS} from '../../config/pricingPlans'
import PricingPlan from '../PricingPlan'
import {sectionMainTitle} from '../../styles/section.style'
import SignupForm from '../SignupForm'

export default function Pricing() {
  return (
    <div css={pricingCss}>
      <div css={pricingBgCss}></div>
      <div className="container-lg">
        <h2 css={[pricingTitleCss, sectionMainTitle]}>Pricing</h2>
        <div css={plansCss}>
          {Object.values(PRICING_PLANS).map((plan) => (
            <PricingPlan key={plan.id} className="mr3 ml3" plan={plan} />
          ))}
        </div>
        <SignupForm />
      </div>
    </div>
  )
}
