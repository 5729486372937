export const signup = async (email: string) => {
  const tmpError = 'Service temporary unavailable. Please try again in a few minutes.'
  let resp
  let data

  try {
    resp = await fetch('https://us-central1-atayoo.cloudfunctions.net/beta-signup/signup', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({email}),
    })
  } catch (error) {
    throw Error(tmpError)
  }

  if (!resp.ok) {
    data = await resp.json()
    throw Error(data?.error?.message || tmpError)
  }
}
