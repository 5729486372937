import {css} from '@emotion/react'
import {PHONES_AND_DOWN_QUERY, TABLETS_AND_UP_QUERY} from '../../constants/mediaQueries'

export const faqSectionCss = css({
  padding: '6rem 0',
  [PHONES_AND_DOWN_QUERY]: {
    padding: '5rem 0 3rem',
  },
})

export const faqTitleCss = css({
  textAlign: 'center',
})

export const qAndAsCss = css({
  display: 'flex',
  flexWrap: 'wrap',
  margin: '60px auto 0',
})

export const qAndACss = css({
  color: '#24292e',
  paddingBottom: '20px',
  [PHONES_AND_DOWN_QUERY]: {
    flexBasis: '100%',
  },
  [TABLETS_AND_UP_QUERY]: {
    flexBasis: 'calc(50% - 30px)',
    paddingRight: '30px',
    paddingLeft: '30px',
    '&:nth-of-type(2n+1)': {
      paddingLeft: 0,
    },
    '&:nth-of-type(2n)': {
      paddingRight: 0,
    },
  },
})

export const questionCss = css({
  fontSize: '22px',
  fontWeight: 700,
  margin: 0,
  [PHONES_AND_DOWN_QUERY]: {
    fontSize: '20px',
  },
})

export const answerCss = css({
  fontSize: '1rem',
  color: '#586069',
})
