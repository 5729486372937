import {css} from '@emotion/react'
import {DESKTOPS_AND_UP_QUERY, PHONES_AND_DOWN_QUERY, TABLETS_ONLY_QUERY} from '../../constants/mediaQueries'

export const pricingCss = css({
  position: 'relative',
  padding: '5rem 0',
})

export const pricingBgCss = css({
  position: 'absolute',
  left: 0,
  background: '#f6f9fc',
  width: '100%',
  height: '100%',
  transform: 'skewY(-10deg)',
  transformOrigin: 0,
  zIndex: -1,
  [PHONES_AND_DOWN_QUERY]: {
    top: '5%',
  },
  [TABLETS_ONLY_QUERY]: {
    top: '9%',
  },
  [DESKTOPS_AND_UP_QUERY]: {
    top: '15%',
  },
})

export const pricingTitleCss = css({
  textAlign: 'center',
})

export const plansCss = css({
  display: 'flex',
  flexDirection: 'row',
  margin: '60px auto 60px',
  flexWrap: 'wrap',
  justifyContent: 'center',
})
