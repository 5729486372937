import {css} from '@emotion/react'

export const header = css({
  position: 'relative',
  top: 0,
  left: 0,
  width: '100%',
  margin: '0 auto',
  background: '#f0e0fa',
})

export const headerWrapper = css({
  width: '100%',
  padding: '0 30px',
  height: '70px',
  lineHeight: '25px',
  maxWidth: '1000px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  margin: '0 auto',
  boxSizing: 'border-box',
})
export const logoWrapper = css({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
})

export const logoImg = css({
  width: '35px',
  height: '35px',
  marginRight: '15px',
})
export const logoText = css({
  fontSize: '18px',
  color: '#8f6bfc',
  fontWeight: 500,
  letterSpacing: 0.5,
})
