import React from 'react'
import {featureCss, featureIconCss, featuresCss} from './Features.style'
import Octicon from '../Octicon'

const features = [
  {
    title: 'Fast and powerful search',
    icon: 'rocket',
    description:
      'Parallel searches (locally and on GitHub) to give you the most relevant results in a second. It means that you can use all the built-in search filters of GitHub.',
  },
  {
    title: 'Search in your history',
    icon: 'history',
    description:
      'Atayoo stores locally the items you recently visited (issues, pull requests, projects, etc...) so you can find them instantly',
  },
  {
    title: 'Workspaces',
    icon: 'organization',
    description:
      'Organize your favorite repositories under workspaces to quickly search in the users/organizations you work in',
  },
  {
    title: 'Accessible from anywhere',
    icon: 'globe',
    description: 'Search for an existing bug, switch to a pull request, etc... from any websites',
  },
  {
    title: 'Keyboard first design',
    icon: 'keyboard',
    description: 'Forget about your mouse, there is a keyboard shortcut for every action',
  },
  {
    title: 'Template queries',
    icon: 'code-square',
    description: 'Built-in templating feature to write long or redundant search queries faster',
  },
]
export default function Features() {
  return (
    <div className="container-lg">
      <div css={featuresCss}>
        {features.map((feature) => (
          <div key={feature.title} css={featureCss}>
            <Octicon css={featureIconCss} icon={feature.icon} width="26px" height="26px" />
            <div>
              <h5>{feature.title}</h5>
              <p>{feature.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
