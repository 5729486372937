import React from 'react'

import {
  spinner,
  spinnerPart,
  spinnerPart1,
  spinnerPart2,
  spinnerPart3,
  spinnerPart4,
  spinnerPart5,
  spinnerPart6,
  spinnerPart7,
  spinnerPart8,
} from './OctocatSpinner.style'

type Props = {
  width: number | string
  height: number | string
  className?: string
}

export default function OctocatSpinner({width, height, className}: Props) {
  return (
    <svg
      className={className}
      css={spinner}
      viewBox="0 0 512 512"
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        css={[spinnerPart, spinnerPart1]}
        d="m332.289429,87.087219c60.033295,-20.366676 114.402222,-21.83609 172.935547,1.047241l-11.403595,32.850952c-43.333344,
        -15.5 -104.147491,-15.984329 -148.480804,-0.734329"
        transform="rotate(45 418.757 96.1885)"
      />
      <path
        css={[spinnerPart, spinnerPart2]}
        d="m398.527466,246.388596c60.033325,-20.366684 114.402222,-21.83609 172.935547,1.047241l-11.403564,32.850967c-43.333374,
        -15.500031 -104.147491,-15.984344 -148.480835,-0.734344"
        transform="rotate(90 484.995 255.49)"
      />
      <path
        css={[spinnerPart, spinnerPart3]}
        d="m331.332214,404.823669c60.033325,-20.366699 114.402252,-21.83609 172.935547,1.047241l-11.403534,32.850952c-43.333405,
        -15.500031 -104.147522,-15.984344 -148.480865,-0.734344"
        transform="rotate(135 417.8 413.925)"
      />
      <path
        css={[spinnerPart, spinnerPart4]}
        d="m171.58223,470.323669c60.03331,-20.366699 114.402237,-21.83609 172.935532,1.047241l-11.403534,32.850952c-43.333405,
        -15.500031 -104.147522,-15.984344 -148.48085,-0.734344"
        transform="rotate(180 258.05 479.425)"
      />
      <path
        css={[spinnerPart, spinnerPart5]}
        d="m13.0822,406.074005c60.033301,-20.367004 114.401801,-21.835999 172.935805,1.046997l-11.404007,32.850983c-43.332993,
        -15.5 -104.147301,-15.983978 -148.480589,-0.733978"
        transform="rotate(-135 99.55 415.175)"
      />
      <path
        css={[spinnerPart, spinnerPart6]}
        d="m-53.713486,247.091553c60.03331,-20.367004 114.40181,-21.835999 172.935806,1.046997l-11.404022,32.850983c-43.332977,
        -15.5 -104.147277,-15.983978 -148.480576,-0.733978"
        transform="rotate(-90 32.754 256.193)"
      />
      <path
        css={[spinnerPart, spinnerPart7]}
        d="m12.883621,87.061485c60.033295,-20.366669 114.402206,-21.836082 172.935562,1.047249l-11.403595,32.850952c-43.333344,
        -15.5 -104.147507,-15.984329 -148.48082,-0.734337"
        transform="rotate(-45 99.3514 96.1628)"
      />
      <path
        css={[spinnerPart, spinnerPart8]}
        d="m172.906631,20.929741c60.03331,-20.366681 114.402206,-21.836091 172.935562,1.04723l-11.403595,32.85096c-43.333344,
        -15.5 -104.147507,-15.984322 -148.48082,-0.734329"
      />
    </svg>
  )
}
