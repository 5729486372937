import React from 'react'
import {header, headerWrapper, logoImg, logoText, logoWrapper} from './Header.style'
import {APP_NAME} from '../../../constants'

export default function Header() {
  return (
    <header css={header}>
      <div css={headerWrapper}>
        <div css={logoWrapper}>
          <img css={logoImg} src="/images/atayoo-logo.png" alt="atayoo-logo" />
          <div css={logoText}>{APP_NAME}</div>
        </div>
      </div>
    </header>
  )
}
