import {css} from '@emotion/react'
import {PHONES_AND_DOWN_QUERY} from '../../constants/mediaQueries'

export const signupFormWrapper = css({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  overflow: 'hidden',
})

export const signupForm = css({
  display: 'flex',
  [PHONES_AND_DOWN_QUERY]: {
    flexDirection: 'column',
  },
})

export const signupEmailInput = css({
  borderRadius: '5px 0 0 5px ',
  outline: 'none',
  boxSizing: 'border-box',
  padding: '5px 20px',
  height: '47px',
  border: '1px solid',
  borderColor: '#c1c2c4',
  borderRight: 'none',
  fontWeight: 400,
  fontSize: '1.1rem',
  lineHeight: '1.1rem',
  width: '280px',
  color: '#24292e',
  [PHONES_AND_DOWN_QUERY]: {
    borderRadius: '5px',
    borderRight: '1px solid #c1c2c4',
    marginBottom: '5px',
    '::-webkit-input-placeholder': {
      textAlign: 'center',
    },
    '::-moz-input-placeholder': {
      textAlign: 'center',
    },
  },
})

export const signupButton = css({
  borderRadius: '0 5px 5px 0',
  [PHONES_AND_DOWN_QUERY]: {
    display: 'block',
    textAlign: 'center',
    borderRadius: '5px',
  },
})

export const signupButtonLoader = css({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
})

export const signupMeta = css({
  color: '#586069!important',
  fontSize: '13px',
  a: {
    color: '#586069!important',
    textDecoration: 'underline',
    '&:hover': {
      color: '#0366d6!important',
    },
  },
})
