import React from 'react'
import Octicon from '../Octicon'
import {
  planUpdateCss,
  planCss,
  planCurrencyCss,
  planFeatureCss,
  planHeaderCss,
  planIntervalCss,
  planNameCss,
  planPriceCss,
  planPriceWrapperCss,
} from './PricingPlan.style'
import {IPricingPlan} from '../../config/pricingPlans'

type Props = {
  plan: IPricingPlan
  className: string
}

export default function PricingPlan(props: Props) {
  const {plan, className} = props
  const {pricePerMonth} = plan
  const betaDiscounted = plan.discount?.id === 'beta'

  return (
    <div css={planCss} className={className}>
      <div css={planHeaderCss}>
        <div css={planNameCss}>{plan.name}</div>
      </div>

      <div css={planPriceWrapperCss} className="mb5">
        <span css={planCurrencyCss}>$</span>
        <span css={planPriceCss}>{pricePerMonth || plan.price}</span>
        <span css={planIntervalCss}>
          {' '}
          / {pricePerMonth ? 'month' : plan.interval}
          {pricePerMonth && plan.interval === 'year' ? `. $${plan.price} billed annually.` : ''}
        </span>
      </div>
      {pricePerMonth === 0 && (
        <div css={planUpdateCss} className="mb5">
          <b>Free forever</b>
        </div>
      )}
      {betaDiscounted && (
        <div css={planUpdateCss} className="mb5">
          <b>Free while in Beta</b>
        </div>
      )}
      <div>
        {plan.features.map((feature) => (
          <div key={feature} css={planFeatureCss} className="mb1">
            <Octicon icon="verified" width="17px" height="17px" style={{color: 'rgb(50, 211, 139)'}} />
            <span className="ml3">{feature}</span>
          </div>
        ))}
      </div>
    </div>
  )
}
