import React from 'react'
import {useState, useEffect} from 'react'
import {SerializedStyles} from '@emotion/utils'

type Props = {
  alt: string
  lowResImage: string
  highResImage: string
  width: string
  height: string
  css: SerializedStyles
}

const LowQualityPlaceholderImage = ({alt, lowResImage, highResImage, ...rest}: Props) => {
  const [image, setImage] = useState<string>(lowResImage)

  useEffect(() => {
    const img = new Image()
    img.src = highResImage
    img.onload = () => {
      setImage(highResImage)
    }
  })

  return <img src={image} alt={alt} {...rest} />
}

export default LowQualityPlaceholderImage
