import {css} from '@emotion/react'
import {PHONES_AND_DOWN_QUERY} from '../../constants/mediaQueries'

export const bannerCss = css({
  position: 'fixed',
  display: 'inline-flex',
  height: 'auto',
  zIndex: 99,
  background: '#fff',
  padding: '10px 15px',
  borderRadius: '5px',
  fontSize: '13px',
  flexDirection: 'row',
  width: '500px',
  justifyContent: 'space-between',
  boxShadow: '0px 3px 10px rgba(0,0,0,0.3)',
  left: '50%',
  bottom: '10px',
  transform: 'translateX(-50%)',
  boxSizing: 'border-box',
  alignItems: 'center',
  color: '#586069',
  [PHONES_AND_DOWN_QUERY]: {
    bottom: 0,
    borderRadius: 0,
    width: '100%',
  },
  p: {
    margin: 0,
    paddingRight: '10px',
  },
})

export const bannerButtonsCss = css({
  display: 'flex',
  flexDirection: 'row',
  flexBasis: '260px',
  alignItems: 'flex-start',
  justifyContent: 'flex-end',
  alignContent: 'center',
  [PHONES_AND_DOWN_QUERY]: {
    flexWrap: 'wrap',
  },
})

export const bannerButtonCss = css({
  marginLeft: '5px',
  [PHONES_AND_DOWN_QUERY]: {
    '&:first-of-type': {
      marginBottom: '5px',
    },
  },
})
