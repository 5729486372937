import {css} from '@emotion/react'
import {PHONES_AND_DOWN_QUERY} from '../constants/mediaQueries'

export const sectionMainTitle = css({
  fontSize: '45px',
  fontWeight: 500,
  margin: 0,
  [PHONES_AND_DOWN_QUERY]: {
    fontSize: '40px',
  },
})
