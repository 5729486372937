import React, {ReactNode} from 'react'
import Head from 'next/head'
import CookieConsentBanner from '../CookieConsentBanner'

type Props = {
  title: string
  children: ReactNode
}

export default function Layout(props: Props) {
  return (
    <>
      <Head>
        <title>{props.title}</title>
      </Head>
      <>
        <CookieConsentBanner />
        {props.children}
      </>
    </>
  )
}
