import React from 'react'
import Hero from '../components/Hero'
import Layout from '../components/Layout/Layout'
import Features from '../components/Features'
import Header from '../components/Header'
import Pricing from '../components/Pricing'
import Faq from '../components/Faq/Faq'
import Footer from '../components/Footer/Footer'
import Head from 'next/head'
import {getFullUrl} from '../utils/url'

export default function Index() {
  const title = 'Atayoo - A quick switcher for GitHub'
  const description =
    'Atayoo enables you to quickly search and navigate across Github,\n' + 'no matter the website you are on.'
  const image = getFullUrl('/images/open-graph-app-preview.jpg')
  return (
    <Layout title={title}>
      <Head>
        <link rel="icon" type="image/png" href={getFullUrl('/favicon.ico')} />
        <meta name="Description" content={description} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:image" content={image} />
        <meta property="og:url" content={getFullUrl()} />
        <meta property="og:description" content={description} />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:site" content="@getatayoo" />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:image" content={image} />
        <meta property="twitter:description" content={description} />
      </Head>
      <Header />
      <Hero />
      <Features />
      <Pricing />
      <Faq />
      <Footer />
    </Layout>
  )
}
