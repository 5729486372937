import {css} from '@emotion/react'
import {PHONES_AND_DOWN_QUERY} from '../../constants/mediaQueries'

export const heroContainer = css({
  position: 'relative',
})

export const heroBg = css({
  position: 'absolute',
  top: 0,
  left: 0,
  background: '#f0e0fa',
  width: '100%',
  height: '85%',
  transform: 'skewY(-10deg)',
  transformOrigin: 0,
  zIndex: -1,
})

export const appPreviewContainerImg = css({
  display: 'flex',
  overflow: 'hidden',
  borderRadius: '10px',
  boxShadow: '1px 1px 20px rgba(0, 0, 0, 0.5)',
})

export const appPreviewImg = css({
  width: '100%',
  height: '100%',
})

export const heroHeader = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  paddingTop: '6rem',
  paddingBottom: '6rem',
  [PHONES_AND_DOWN_QUERY]: {
    paddingTop: '3rem',
    paddingBottom: '3rem',
  },
})

export const heroHeaderTitle = css({
  margin: 0,
})

export const heroHeaderSubTitle = css({
  fontSize: '20px',
  color: '#586069',
  marginTop: 0,
})
