import {css} from '@emotion/react'
import {DESKTOPS_AND_UP_QUERY, PHONES_AND_DOWN_QUERY, TABLETS_ONLY_QUERY} from '../../constants/mediaQueries'

export const featuresCss = css({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  padding: '6rem 0',
  boxSizing: 'border-box',
  alignItems: 'baseline',
  [PHONES_AND_DOWN_QUERY]: {
    padding: '3rem 0',
  },
})

export const featureCss = css({
  display: 'flex',
  height: 'auto',
  flexDirection: 'column',
  alignContent: 'center',
  textAlign: 'center',
  padding: '10px 20px',
  boxSizing: 'border-box',

  [PHONES_AND_DOWN_QUERY]: {
    flexBasis: '100%',
    paddingRight: 0,
    paddingLeft: 0,
    marginTop: '20px',
    textAlign: 'left',
  },
  [TABLETS_ONLY_QUERY]: {
    flexBasis: '50%',
    '&:nth-of-type(even)': {
      paddingRight: '0',
    },
    '&:nth-of-type(odd)': {
      paddingLeft: '0',
    },
    '&:nth-of-type(n+2)': {
      marginTop: '20px',
    },
  },
  [DESKTOPS_AND_UP_QUERY]: {
    flexBasis: '33%',
    '&:nth-of-type(3n)': {
      paddingRight: '0',
    },
    '&:nth-of-type(3n+1)': {
      paddingLeft: '0',
    },
    '&:nth-of-type(n+4)': {
      marginTop: '30px',
    },
  },

  h5: {
    fontSize: '22px',
    margin: '10px 0',
    [PHONES_AND_DOWN_QUERY]: {
      fontSize: '20px',
    },
  },
  p: {
    marginTop: 0,
    color: '#586069',
    fontSize: '1rem',
  },
})

export const featureIconCss = css({
  color: '#8E6DFF',
  marginBottom: '15px',
  justifyContent: 'center',

  [PHONES_AND_DOWN_QUERY]: {
    marginBottom: '5px',
    justifyContent: 'flex-start',
  },
})
