import React from 'react'
import {
  heroContainer,
  heroBg,
  appPreviewImg,
  heroHeader,
  heroHeaderTitle,
  heroHeaderSubTitle,
  appPreviewContainerImg,
} from './Hero.style'
import {sectionMainTitle} from '../../styles/section.style'
import LowQualityPlaceholderImage from '../LowQualityPlaceholderImage'
import SignupForm from '../SignupForm/SignupForm'

export default function Hero() {
  return (
    <div css={heroContainer}>
      <div css={heroBg}></div>
      <div className="container-lg" css={heroHeader}>
        <h1 css={[heroHeaderTitle, sectionMainTitle]}>A quick switcher for GitHub</h1>
        <p css={heroHeaderSubTitle}>
          Atayoo enables you to quickly search and navigate across Github,
          <br />
          no matter the website you are on.
        </p>
        <SignupForm autoFocus />
      </div>
      <div className="container-lg">
        <div css={appPreviewContainerImg}>
          <LowQualityPlaceholderImage
            alt="app-preview"
            css={appPreviewImg}
            width="2000"
            height="1298"
            lowResImage="images/app-preview-placeholder.jpg"
            highResImage="images/app-preview.png"
          />
        </div>
      </div>
    </div>
  )
}
