import {css} from '@emotion/react'

export const buttonStyles = css({
  position: 'relative',
  display: 'inline-flex',
  // !important: these rules need to be overrode on Safari
  width: 'auto',
  height: 'auto',
  borderRadius: '5px',
  boxSizing: 'border-box',
  border: 'none',
  outline: 'none',
  cursor: 'pointer',
  alignItems: 'center',
  color: '#ffffff',
  fontWeight: 400,
  fontSize: '1rem',
  lineHeight: '1rem',

  '&:hover': {
    outline: 'none',
    textDecoration: 'none',
  },
  '&:focus': {
    outline: 'none',
    textDecoration: 'none',
  },
  '&:active': {
    outline: 'none',
    textDecoration: 'none',
  },
  '&.small': {
    padding: '10px',
    fontSize: '0.9rem',
    lineHeight: '0.9rem',
  },
  '&.large': {
    padding: '15px 25px',
    fontSize: '1.1rem',
    lineHeight: '1.1rem',
  },
  '&.primary': {
    backgroundColor: '#8E6DFF',

    '&:hover': {
      backgroundColor: ' #9779fe',
    },
    '&:active': {
      backgroundColor: '#7b55fe',
    },
  },
  '&.success': {
    backgroundColor: 'rgba(50, 211, 139, 1)',
    '&:hover': {
      backgroundColor: 'rgb(50, 201, 129)',
    },
    '&:active': {
      backgroundColor: 'rgb(43, 181, 120)',
    },
  },
  '&.secondary': {
    backgroundColor: '#979ba0',

    '&:hover': {
      backgroundColor: '#a5a8ac',
    },
    '&:active': {
      backgroundColor: '#717478',
    },
  },
})
