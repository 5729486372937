import React, {ReactNode} from 'react'
import classnames from 'classnames'
import {buttonStyles} from './Button.style'

type Props = {
  [index: string]: unknown
  className?: string
  children?: ReactNode
  style?: {[index: string]: string | number | null}
  status: 'secondary' | 'primary' | 'success' | 'danger'
  size: 'small' | 'large'
}

export default function Button(props: Props) {
  const {className, children, size, status, ...buttonProps} = props
  return (
    <div className={classnames(className, status, size)} css={buttonStyles} {...buttonProps}>
      {children}
    </div>
  )
}

Button.defaultProps = {
  size: 'medium',
}
