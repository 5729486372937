import {css} from '@emotion/react'

export const separatorCss = css({
  width: '40%',
  maxWidth: '150px',
  height: '2px',
  background: '#dbdde0',
  margin: '0 auto 40px',
})

export const linksCss = css({
  margin: '0 auto',
  textAlign: 'center',
  display: 'flex',
  alignContent: 'center',
  flexWrap: 'wrap',
  justifyContent: 'center',
  paddingBottom: '40px',
})

export const linkCss = css({
  whiteSpace: 'nowrap',
  padding: '5px 10px 0px',
  a: {
    color: '#8E6DFF',
  },
})

export const copyrightCss = css({
  textAlign: 'center',
  fontSize: '10px',
  paddingBottom: '40px',
  color: '#586069',
})
