import React, {useEffect, useRef, useState} from 'react'
import {
  signupMeta,
  signupEmailInput,
  signupForm,
  signupButton,
  signupButtonLoader,
  signupFormWrapper,
} from './SignupForm.style'
import Button from '../Button'
import {signup} from '../../clients/betaSignup'
import {useNotifications} from 'reapop'
import OctocatSpinner from '../OctocatSpinner'
import {validateEmail} from '../../utils/email'

type Props = {
  autoFocus: boolean
}
export default function SignupForm(props: Props) {
  const {autoFocus} = props
  const [email, setEmail] = useState<string>('')
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [isValidEmail, setIsValidEmail] = useState<boolean>(false)
  const signupFormRef = useRef(null)
  const {notify} = useNotifications()

  const onSubmit = async () => {
    if (!isValidEmail || isSubmitting) {
      return
    }

    setIsSubmitting(true)

    try {
      await signup(email)
      notify({
        message: 'Thank you! We will email you shortly. 👌',
        status: 'success',
        dismissAfter: 4000,
      })
      signupFormRef.current.animate([{maxHeight: '200px'}, {maxHeight: 0}], {
        fill: 'forwards',
        duration: 400,
      })
      setEmail('')
    } catch (error) {
      notify({message: error.message, status: 'error', dismissAfter: 4000})
    } finally {
      setIsSubmitting(false)
    }
  }

  useEffect(() => {
    setIsValidEmail(validateEmail(email))
  }, [email])

  return (
    <div ref={signupFormRef} css={signupFormWrapper}>
      <div css={signupForm}>
        <input
          aria-label="Your email"
          css={signupEmailInput}
          type="input"
          value={email}
          autoComplete="off"
          autoFocus={autoFocus}
          placeholder="Email"
          onChange={(event) => {
            if (!isSubmitting) {
              setEmail(event.target.value)
            }
          }}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              onSubmit()
            }
          }}
        />
        <Button onClick={onSubmit} css={signupButton} size="large" status="primary" aria-label="Request access">
          <span style={{opacity: isSubmitting ? '0%' : '100%'}}>Request access</span>
          {isSubmitting && <OctocatSpinner css={signupButtonLoader} width="20" height="20" />}
        </Button>
      </div>
      <p className="mb3" css={signupMeta}>
        Now available in beta. Be one of the first to use it!
      </p>
    </div>
  )
}

SignupForm.defaultProps = {
  autoFocus: false,
}
