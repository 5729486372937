import ReactGA from 'react-ga'
import {GOOGLE_ANALYTICS_TRACKING_ID} from '../config/app'

export class GoogleAnalytics {
  static init = () => {
    ReactGA.initialize(GOOGLE_ANALYTICS_TRACKING_ID, {})
    ReactGA.set({anonymizeIp: true})
  }

  static trackPage = () => {
    ReactGA.set({page: window.location.pathname})
    ReactGA.pageview(window.location.pathname)
  }
}
