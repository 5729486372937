import {css, keyframes} from '@emotion/react'

const animation = keyframes({
  '0%, 25%': {
    fill: '#bfbfbf',
  },
  '50%, 75%': {
    fill: '#333333',
  },
})

export const spinner = css({
  fill: '#bfbfbf',
})

export const spinnerPart = css({
  animationName: animation,
  animationDuration: '0.420s',
  animationIterationCount: 'infinite',
  animationTimingFunction: 'ease-out',
  fill: '#bfbfbf',
})

export const spinnerPart1 = css({
  animationDelay: '0.000s',
})

export const spinnerPart2 = css({
  animationDelay: '0.060s',
})

export const spinnerPart3 = css({
  animationDelay: '0.120s',
})

export const spinnerPart4 = css({
  animationDelay: '0.180s',
})

export const spinnerPart5 = css({
  animationDelay: '0.240s',
})

export const spinnerPart6 = css({
  animationDelay: '0.300s',
})

export const spinnerPart7 = css({
  animationDelay: '0.360s',
})

export const spinnerPart8 = css({
  animationDelay: '0.400s',
})
